import React from 'react'

function GitlabButton(props) {
  return (
    <a href={props.url} target="_blank">
        <div className="inline-flex px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:hover:bg-gray-200 focus:bg-gray-100 dark:focus:bg-gray-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="currentColor" viewBox="0 0 18 16">
                <path d="M8.64640338,15.9375303 L5.46638646,6.15050159 L11.8264203,6.15050159 L8.64640338,15.9375303 Z M8.64640338,15.9375138 L1.00967182,6.15048513 L5.46638646,6.15048513 L8.64640338,15.9375138 Z M1.00968005,6.15050982 L8.64641154,15.9375385 L0.282489853,9.86077637 C0.0517246534,9.69313418 -0.0448115733,9.39595409 0.0433301989,9.12469799 L1.00968005,6.15050982 Z M1.00968005,6.15051805 L2.92493245,0.255882725 C3.02344384,-0.0473875186 3.4525486,-0.0474698172 3.55106,0.255882725 L5.46639469,6.15051805 L1.00968005,6.15051805 Z M8.64640338,15.9375138 L11.8264203,6.15048513 L16.2831349,6.15048513 L8.64640338,15.9375138 Z M16.2831349,6.15050982 L17.2494847,9.12469799 C17.3376265,9.39595409 17.2410903,9.69313418 17.0103251,9.86077637 L8.64640338,15.9375385 L16.2831349,6.15050982 Z M16.2831349,6.15051805 L11.8264203,6.15051805 L13.7417549,0.255882725 C13.8402663,-0.0474698172 14.2693711,-0.0473875186 14.3678825,0.255882725 L16.2831349,6.15051805 Z"/>
            </svg>
        </div>
    </a>
  )
}

export default GitlabButton
