import React from 'react'

function Footer() {
  return (
    <div className="py-10">

    </div>
  )
}

export default Footer